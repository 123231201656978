import React, { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql, Link } from 'gatsby';
import useDarkMode from 'use-dark-mode';
import Toggle from 'react-toggle';

import "react-toggle/style.css";
import "../styles/styles.css";

const Layout = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useRef(null);
    
    useEffect(() => {

        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
    `);

    let preferredColorMode = 'light';
    const darkMode = useDarkMode(preferredColorMode);

    return (
        <div className={`py-8 px-8 md:px-24 flex flex-col min-h-screen whole`}>
            <Helmet titleTemplate={`%s - ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title} defer={false} >
                {/* The charset, viewport and author meta tags will always have the same value, so we hard code them! */}
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="Raviteja V Guttula" />
                <meta name="description" content={data.site.siteMetadata.description} />
                <meta name="twitter:site" content="@thisisgvrt" />
                <meta name="twitter:creator" content="@thisisgvrt" />
                <meta name="twitter:title" content="Captain's log" />
                <meta name="twitter:description" content={data.site.siteMetadata.description} />
                <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Chelsea+Market&family=IBM+Plex+Mono:wght@300&family=Montserrat:wght@400;600&family=Raleway&display=swap" />
            </Helmet>
            <header className="header flex flex-row justify-between items-baseline">
                <strong className="font-title font-extrabold md:text-3xl text-2xl">
                    <a href="/">{data.site.siteMetadata.title}</a>
                </strong>
                <nav className="nav flex flex-row items-bottom">
                    <label className="nav__link">
                        <Toggle
                            defaultChecked={darkMode.value}
                            icons={{
                                unchecked: <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" stroke="#000000">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                </svg>,
                                checked: <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24" stroke="#ffffff">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>,
                            }}
                            onChange={() => darkMode.toggle()} />
                    </label>
                    <div className="inline-flex flex-row hidden lg:block">
                        <Link className="nav__link" activeClassName="link-active" to="/articles">Articles</Link>
                        <Link className="nav__link" activeClassName="link-active" to="/dev-log">Dev Log</Link>
                        <Link className="nav__link" to="https://thisisgvrt.me/?r=b">About me</Link>
                    </div>
                    <div className="relative inline-block flex flex-row block lg:hidden">
                        <button className="bg-opacity-0 bg-gray-100 rounded-full flex items-start focus:outline-none"
                            id="options-menu"
                            aria-haspopup="true"
                            aria-expanded="true"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span className="sr-only">Open options</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                            </svg>
                        </button>
                        <Transition
                            show={isOpen}
                            enter="transition ease-out duration-200 transform"
                            enterFrom="opacity-0 scale-50"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-50"
                        >
                            <div className="origin-top-right absolute right-0 mt-2 w-24 rounded-md shadow-lg three-ring-overlay ring-1 ring-black ring-opacity-5"
                                onMouseLeave={() => setIsOpen(!isOpen)}
                                ref={wrapperRef}
                            >
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    <Link to="/articles" className="block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem">Articles</Link>
                                    <Link to="/dev-log" className="block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem">Dev Log</Link>
                                    <Link to="https://thisisgvrt.me/?r=b" className="block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem">About me</Link>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </nav>
            </header>
            <div className="flex-grow">
                {children}
            </div>
            <footer className="text-center">
                © {new Date().getFullYear()} Raviteja Guttula
            </footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;